<template>
  <h-layout>
    <h-layout-content class="login-main">
      <div class="poster">
        <div class="logo-title">
          <img src="@/assets/svg/logo文字-1920.png" alt=""  />
        </div>
        <div class="login-form">
          <eits-login
            style="width: 400px; height: auto"
            product-code="xxx"
            verify-code-url="/webapi/portal/api/eits/v2/verifyCode"
            challenge-code-url="/webapi/portal/api/eits/v1/challengeCode"
            login-url="/webapi/portal/api/eits/v1/login"
            phoneNoVerifyUrl="/webapi/portal/api/eits/v1/phone/status"
            changeWeakPwdUrl="/webapi/portal/api/eits/v1/user/pwd/update"
            getVerifyUrl="/webapi/portal/api/eits/v1/phone/verifyCode"
            checkVerifyUrl="/webapi/portal/api/eits/v1/phone/checkCode"
            @forget-pwd="forgetPwd"
            @login-back="loginBack"
            @check-back="checkBack"
          >
          </eits-login>
        </div>
        <div></div>
    </div>
    </h-layout-content>
  </h-layout>
</template>

<script>
import { mapState, mapMutations } from 'vuex'
import { fetchMenuInfo } from '@/api/index'
// import { Message } from 'hui'

export default {
  data () {
    return {
      clientData: {}
    }
  },
  computed: {
    ...mapState('GLOBAL', ['session'])
  },

  methods: {
    ...mapMutations('GLOBAL', ['setSession']),

    forgetPwd () {},

    loginBack (result) {
      console.log(result)
      this.setSession(result.data)
      location.href = '/portal'
    },

    // 获取菜单列表
    async getMenus (callback) {
      const { data, code } = await fetchMenuInfo()
      if (code === '0' && data.length) {
        callback && callback()
      } else {
        callback()
        // Message.error('账号无访问权限')
      }
    },
    checkBack () {}
  }
}
</script>
<style lang="scss" scoped>
.login-main {
  display: flex;
  min-width: 0;
}

.logo-title {
  position: absolute;
  left: 25px;
  top: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.poster {
  width: 0;
  flex: 1 1 auto;
  overflow: hidden;
  min-width: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #2e5bff;
  background-image: url("~@/assets/images/登录页_1920.png");
  background-repeat: no-repeat;
  background-size: 100% 100%;
  .poster-img {
    width: 80%;
  }
}

.login-form {
  position: absolute;
  // width: 0%;
  // flex: 1 1 auto;
  // display: flex;
  // justify-content: center;
  // align-items: center;
  background-color: white;
  top:28%;
  right:15%;
  box-shadow: 4px 4px 24px rgba(0,0,0,0.23);
}
.client-wrap {
  position: absolute;
  bottom: 24px;
  right: 0;
  display: flex;
  .client {
    text-align: center;
    margin-right: 52px;
    div {
      font-size: 12px;
      color: #333;
    }
    img {
      width: 78px;
      height: 78px;
      margin: 12px 0;
    }
    a {
      display: block;
      font-size: 12px;
      color: #3366ff;
      text-decoration: none;
    }
  }
}
</style>
